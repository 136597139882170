/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
.dense-1 {
  --mat-table-header-container-height: 52px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
}
.dense-1 .mat-mdc-form-field-infix {
  min-height: 52px;
}
.dense-1 .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px;
}
.dense-1 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -32.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-1 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}
.dense-1 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 22px;
  padding-bottom: 6px;
}
.dense-1 .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 14px;
}
.dense-1 {
  --mdc-checkbox-state-layer-size: 36px;
}

.dense-1 .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}
.dense-1 .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 44px;
}
.dense-1 .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 36px;
}
.dense-1 .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-two-line-container-height: 60px;
  --mdc-list-list-item-three-line-container-height: 84px;
}
.dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 52px;
}
.dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dense-1 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 68px;
}
.dense-1 {
  --mat-paginator-container-size: 52px;
}

.dense-1 .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dense-1 .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dense-1 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-1 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-1 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-1 .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-1 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dense-1 .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 44px;
}
.dense-1 .mat-mdc-button.mat-mdc-button-base,
.dense-1 .mat-mdc-raised-button.mat-mdc-button-base,
.dense-1 .mat-mdc-unelevated-button.mat-mdc-button-base,
.dense-1 .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
}
.dense-1 .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.dense-1 .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.dense-1 .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.dense-1 .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.dense-1 .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 44px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 10px;
}
.dense-1 {
  --mat-expansion-header-collapsed-state-height: 44px;
  --mat-expansion-header-expanded-state-height: 60px;
}

.dense-1 {
  --mat-stepper-header-height: 68px;
}

.dense-1 {
  --mat-toolbar-standard-height: 60px;
  --mat-toolbar-mobile-height: 52px;
}

.dense-1 .mat-tree-node {
  min-height: 44px;
}
.dense-1 {
  --mat-standard-button-toggle-height: 44px;
}

.dense-2 {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}
.dense-2 .mat-mdc-form-field-infix {
  min-height: 48px;
}
.dense-2 .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 24px;
}
.dense-2 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -30.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-2 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.dense-2 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.dense-2 .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.dense-2 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dense-2 .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.dense-2 {
  --mdc-checkbox-state-layer-size: 32px;
}

.dense-2 .mat-mdc-checkbox-touch-target {
  display: none;
}
.dense-2 .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.dense-2 .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 40px;
}
.dense-2 .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 32px;
}
.dense-2 .mat-mdc-radio-touch-target {
  display: none;
}
.dense-2 .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
.dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dense-2 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}
.dense-2 {
  --mat-paginator-container-size: 48px;
}

.dense-2 .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dense-2 .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dense-2 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-2 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-2 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-2 .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-2 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dense-2 .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}
.dense-2 .mat-mdc-button.mat-mdc-button-base,
.dense-2 .mat-mdc-raised-button.mat-mdc-button-base,
.dense-2 .mat-mdc-unelevated-button.mat-mdc-button-base,
.dense-2 .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 28px;
  margin-top: 0;
  margin-bottom: 0;
}
.dense-2 .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.dense-2 .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.dense-2 .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.dense-2 .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.dense-2 .mat-mdc-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-2 .mat-mdc-raised-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-2 .mat-mdc-unelevated-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-2 .mat-mdc-outlined-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dense-2 .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.dense-2 .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dense-2 {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

.dense-2 {
  --mat-stepper-header-height: 64px;
}

.dense-2 {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

.dense-2 .mat-tree-node {
  min-height: 40px;
}
.dense-2 {
  --mat-standard-button-toggle-height: 40px;
}

.dense-3 {
  --mat-table-header-container-height: 44px;
  --mat-table-footer-container-height: 40px;
  --mat-table-row-item-container-height: 40px;
}
.dense-3 .mat-mdc-form-field-infix {
  min-height: 44px;
}
.dense-3 .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px;
}
.dense-3 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -28.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-3 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dense-3 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dense-3 .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dense-3 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dense-3 .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}
.dense-3 {
  --mdc-checkbox-state-layer-size: 28px;
}

.dense-3 .mat-mdc-checkbox-touch-target {
  display: none;
}
.dense-3 .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.dense-3 .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 36px;
}
.dense-3 .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 28px;
}
.dense-3 .mat-mdc-radio-touch-target {
  display: none;
}
.dense-3 .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
}
.dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
.dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dense-3 .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}
.dense-3 {
  --mat-paginator-container-size: 40px;
}

.dense-3 .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dense-3 .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dense-3 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dense-3 .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-3 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-3 .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dense-3 .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dense-3 .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 36px;
}
.dense-3 .mat-mdc-button.mat-mdc-button-base,
.dense-3 .mat-mdc-raised-button.mat-mdc-button-base,
.dense-3 .mat-mdc-unelevated-button.mat-mdc-button-base,
.dense-3 .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}
.dense-3 .mat-mdc-button.mat-mdc-button-base .mdc-button__touch,
.dense-3 .mat-mdc-raised-button.mat-mdc-button-base .mdc-button__touch,
.dense-3 .mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__touch,
.dense-3 .mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__touch {
  height: 100%;
}
.dense-3 .mat-mdc-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-3 .mat-mdc-raised-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-3 .mat-mdc-unelevated-button.mat-mdc-button-base .mat-mdc-button-touch-target,
.dense-3 .mat-mdc-outlined-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dense-3 .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}
.dense-3 .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dense-3 {
  --mat-expansion-header-collapsed-state-height: 36px;
  --mat-expansion-header-expanded-state-height: 48px;
}

.dense-3 {
  --mat-stepper-header-height: 60px;
}

.dense-3 {
  --mat-toolbar-standard-height: 52px;
  --mat-toolbar-mobile-height: 44px;
}

.dense-3 .mat-tree-node {
  min-height: 36px;
}
.dense-3 {
  --mat-standard-button-toggle-height: 36px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.mat-mdc-row:nth-child(2n+1) {
  background-color: rgba(0, 0, 0, 0.03);
}

.mat-mdc-row:nth-child(2n) {
  background-color: #ffffff;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.toolbar-item-spacer {
  flex: 1 1 auto;
}

.amount-right-align {
  text-align: right;
}

input.amount-right-align::-webkit-outer-spin-button,
input.amount-right-align::-webkit-inner-spin-button {
  display: none;
}

input.amount-right-align {
  -moz-appearance: textfield;
}

.full-width {
  width: 100%;
}

.mt-2 {
  margin-top: 10px;
}

.mt-4 {
  margin-top: 15px;
}

.float-right {
  float: right;
}