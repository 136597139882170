/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';



.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  // font-size: 8px;
}

.spacer {
  flex: 1 1 auto;
}

.mat-mdc-row:nth-child(2n+1){
  background-color: rgba(0,0,0,.03);
}
.mat-mdc-row:nth-child(2n){
  background-color:#ffffff;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.toolbar-item-spacer {
  flex: 1 1 auto;
}



.amount-right-align {
  text-align: right;
}

input.amount-right-align::-webkit-outer-spin-button,
input.amount-right-align::-webkit-inner-spin-button {
  display: none;
}

input.amount-right-align {
  -moz-appearance: textfield;
}

.full-width{
  width: 100%;
}

.mt-2{
  margin-top: 10px;
}

.mt-4{
  margin-top: 15px;
}

.float-right{
  float: right;
}